

import JustValidate from 'just-validate'; 
export default {

    name: "newsletter",
    props: {
        content: Object
    },
    data(){
        return {
            form: {
                success: false,
                error: null,
                company_name: '',
                email_address: ''
            }
        }
    },
    methods:{
        
        encode (data) {
        return Object.keys(data)
            .map(
                key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
            )
            .join("&");
        }
    },
    mounted() {
 
         
        const validation = new JustValidate('form#subscribe_form', {
            errorFieldCssClass: 'is-invalid',
        });

        validation.addField('#email_address', [
            {
                rule: 'required',
                errorMessage: '* email address required',
            },
            {
                rule: 'email',
                errorMessage: '* invalid email address',
            },
            {
                rule: 'minLength',
                value: 4
            },
        ]);
         

        validation.onSuccess((event) => {
            event?.preventDefault();
            

            
            this.form.error = null;
                
                this.$axios.post(
                '/.netlify/functions/newsletter',
                this.form,
                { 
                    baseURL: "",
                    auth: "",                                
                    headers: {
                        common: {
                        Authorization: ''
                        }
                    },
                    config: {
                        auth: ""
                    }
                }).then(resp => {
                    this.form.success = true;
                }).catch(err => {
                    this.form.error = err.response.data.body.message; 
                }); 

        });
    }

}

